<template>
  <li :class="{'active':isActive}">
    <div class="header_hover_main">
      <span>{{ $t("menu.artists") }}</span>
      <img src="/img/threespace/icon/chevron.png" />
    </div>
    <div class="header_hover_menu">
      <router-link to="/drops">
        {{ $t("menu.drops") }}
      </router-link>
      <router-link to="/artist">
        {{ $t("menu.list") }}
      </router-link>
      <router-link to="/apply">
        {{ $t("menu.apply") }}
      </router-link>
    </div>
  </li>
</template>

<script>
export default {
  name: "HeaderDropDownArtists",
  data() {
    return {}
  },
  computed: {
    isActive() {
      const activeLink = ['/drops', '/artist', '/apply']
      return activeLink.includes(this.$route.path);
    }
  },
  methods: {},
}
</script>
