<template>
  <b-modal
    v-model="commonAlertModalInfo.show"
    id="commonAlertModal"
    ref="commonAlertModal"
    footer-class="common_alert_modal_footer text-center d-block"
    body-class="pt-4"
    hide-header
    auto-focus-button="ok"
    button-size="sm"
    centered
    :ok-title="$t('button.confirm')"
    ok-variant="dark"
    ok-only
    @ok="onClickOkBtn"
  >
    <div class="common_alert_modal">
      <div class="close_btn text-right">
        <CLink @click="onClickOkBtn" class="float-right">
          <img src="/img/threespace/icon/x-icon.png" />
        </CLink>
      </div>
      <div class="logo_box">
          <b-img src="/img/threespace/logo/logo_3space_color.png"></b-img>
      </div>
      <p
        class="msg_box"
        v-html="commonAlertModalInfo.msgHtml"
        :class="'font_' + commonAlertModalInfo.fontSize"
      ></p>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: "CommonAlertModal",
  data: function () {
    return {}
  },
  computed: {
    commonAlertModalInfo () {
      return this.$store.state.commonAlertModalInfo;
    }
  },
  methods: {
    onClickOkBtn(e) {
      e.preventDefault ();
      this.$store.commit("setCommonAlertModalInfo", {
        msgHtml: "",
        show: false
      })
    },
  },
}
</script>
